<template>
  <div class='indexbox'>
    <div class="searchbox">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item><a href="list">Assembly Order List</a></el-breadcrumb-item>
        <el-breadcrumb-item>Allocate Via Excel</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="tablebox">
      <div class="bodybox">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 1</p>
              <p>Download Our Allocate List Template File</p>
              <p>This File Has Correct Assembly Geadings The Program Needs To Import Your Assembly Orders List.</p>
            </div>
          </div>
          <el-button @click="downloaddata" :loading='btn_loadinga'>Download Excel Template</el-button>
        </div>
      </div>
      <div class="bodybox">
        <div class="topbox" style="margin-bottom:20px;">
          <div class="left">
            <div class="left">
              <p>Step 2</p>
              <p>Copy Your Assembly Orders List Into The Template</p>
            </div>
          </div>
        </div>
        <div class="listbox" style="margin-bottom:20px;">
          <div class="titbox">
            <p>Required Fields</p>
            <p>Each Assembly Order Should Exist In The Program</p>
          </div>
          <div class="tabbox">
            <p>FIELD NAME</p>
            <p>WHAT IS IT?</p>
          </div>
          <div class="tablist">
            <p>ASSEMBLY #</p>
            <p>The Assembly Orders To Be Mass Allocated</p>
          </div>
        </div>
        <!-- <el-alert title="Do not change the column headingd in the template file.These need to be unchanged for the import to work in the next step." type="error" :closable=false></el-alert> -->
      </div>
      <div class="bodybox" style="border:none;">
        <div class="topbox">
          <div class="left">
            <div class="left">
              <p>Step 3</p>
              <p>Import The Updated Template File</p>
              <p>The File You Import Must Be An Excel File. The Name Of Your File Should End With .xls .xlsx.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bodybox" v-if="show">
        <div class="draggable" v-loading='loading'>
          <el-table :data="tableData" stripe>
            <el-table-column prop="AssemblyNumber" label="ASSEMBLY #" align='center' />
            <el-table-column prop="error" label="Error Description" align='center' show-overflow-tooltip>
              <template slot-scope="scope">
                <img src="../../assets/img/error@2x.png" @click="opendata(scope.row)" width="25" height="25" alt=""
                  v-if="scope.row.error">
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bodybox" style="border:none;">
        <div class="upfile">
          <el-form ref="form" :model="form" :rules="rules" class="formbox">
            <el-form-item prop="file">
              <el-upload :limit='1' class="upload-demo" action='' drag :auto-upload="false" accept=".xlsx, .xls"
                :on-change="Uploadchange" :on-remove='clearFiles' :file-list="fileList">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drag The File Here, Or <em>Click Upload</em></div>
                <div style="color:red" slot="tip">Tip: Only Import Is Allowed “xls” Or “xlsx” Format File！</div>
              </el-upload>
            </el-form-item>
            <el-form-item class="btnbox">
              <el-button type="primary" @click.native.prevent="loadOrders" :loading='btn_loading' v-if="!show">Verify
                File</el-button>
              <el-button class="download" type="primary" :loading='loadinga' v-if="show"
                @click="importProductavailability">Mass Allocate</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="list_show" width="90%" :show-close=false>
      <div class="topbox" slot="title">
        <div class="left">Replenishment List</div>
        <div class="right">
          <el-button type="primary" :loading='btn_loadingd' @click="stockadjustment">Generate Write On & Allocate
          </el-button>
          <el-button type="primary" @click="list_show = false">Cancel</el-button>
        </div>
      </div>

      <el-table :data="list" stripe @selection-change="SelectionChangelist" max-height="400" v-loading='loadinglist'>
        <el-table-column label="Component SKU" prop="ProductCode" width="140" align="center" show-overflow-tooltip
          fixed />
        <el-table-column label="Component Name" prop="Name" width="160" align="center" show-overflow-tooltip />
        <el-table-column label="Component Required Qty" prop="require_qty" align="center" width="220"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color:#19CBBF;font-weight:550;cursor:pointer;" @click="shortageDetail(scope.row)">{{
              scope.row.require_qty
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Component Required Qty" prop="require_qty" align="center" width="220" show-overflow-tooltip /> -->
        <el-table-column label="Component Available Qty" prop="Available" align="center" width="220"
          show-overflow-tooltip />
        <el-table-column label="Stock On Hand" prop="OnHand" align="center" width="140" show-overflow-tooltip />
        <el-table-column label="Shortage Qty" prop="Shortage" align="center" min-width="140" show-overflow-tooltip />
        <el-table-column label="Unit Price For 0 Stock" prop="UnitPrice" min-width="180" align="center"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input :disabled='scope.row.is_UnitPrice' v-model="scope.row.UnitPrice"
              placeholder="Please Enter"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="BatchNumber For 0 Stock" prop="BatchNubmer" min-width="200" align="center"
          show-overflow-tooltip />
      </el-table>
    </el-dialog>

    <el-dialog title="Component Shortage Detail" :visible.sync="detail_show" width="95%">
      <el-table :data="detail_list" stripe max-height="400" v-loading='detail_loading'>
        <el-table-column label="Assembly Order #" prop="AssemblyNumber" align="center" width='160' show-overflow-tooltip
          fixed />
        <el-table-column label="Location" prop="Location" align="center" width='120' show-overflow-tooltip />
        <el-table-column label="SKU" prop="ProductCode" align="center" show-overflow-tooltip />
        <el-table-column label="Product Name" prop="ProductName" align="center" width='130' show-overflow-tooltip />
        <el-table-column label="Quantity" prop="Quantity" align="center" sortable width='140' show-overflow-tooltip />
        <el-table-column label="WIP Date" prop="WIPDate" sortable width='150' align="center" show-overflow-tooltip />
        <el-table-column label="Completion Date" prop="CompletionDate" sortable width='180' align="center"
          show-overflow-tooltip />
        <el-table-column label="Note" prop="Note" align="center" show-overflow-tooltip />
        <el-table-column label="SO#" prop="SO" align="center" show-overflow-tooltip />
        <el-table-column label="Component SKU" width='150' prop="ComponentSKU" align="center" show-overflow-tooltip />
        <el-table-column label="Component Name" width='150' prop="ComponentName" align="center" show-overflow-tooltip />
        <el-table-column label="Component Requried Qty" width='220' prop="TotalQuantity" align="center"
          show-overflow-tooltip />
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
  import { getToken } from '@/utils/auth'
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        // 导入
        show: false,
        fileList: [],
        form: {
          file: '',
        },
        rules: {
          file: [
            { required: true, trigger: "blur", message: 'Please Upload The File' }
          ]
        },
        tableData: [],
        loading: false,
        loadinga: false,
        btn_loading: false,
        btn_loadinga: false,

        btn_loadingd: false,
        loadinglist: false,
        list_show: false,
        list: [],
        listidsarr: [],
        detail_show: false,
        detail_loading: false,
        detail_list: [],
      };
    },
    computed: {
      ...mapGetters(['api_id']),
    },
    methods: {
      // 导入 上传file
      Uploadchange(file) {
        this.form.file = file.raw
      },
      clearFiles() {
        this.fileList = []
        this.form.file = ''
        this.tableData = ''
        this.show = false
      },
      //loadOrders
      loadOrders() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.btn_loading = true
            let data = new FormData();
            data.append("file", this.form.file);
            data.append("api_id", this.$store.state.user.api_id);
            data.append("token", getToken());
            this.$post({
              url: "/app/assembly_orders/importAllocateList",
              upload: true,
              data,
              success: (res) => {
                this.$message({
                  message: 'Finished',
                  type: 'success'
                });
                this.tableData = res.data
                setTimeout(() => {
                  this.show = true
                  this.btn_loading = false
                }, 1500);
              },
              tip: () => {
                setTimeout(() => {
                  this.btn_loading = false
                }, 1500);
              },
            })
          }
        });
      },
      //导入模板下载
      downloaddata() {
        this.btn_loadinga = true
        this.$post({
          url: "/app/assembly_orders/getExcelUrl",
          success: (res) => {
            let url = res.data.url3
            window.open(url)
            setTimeout(() => {
              this.btn_loadinga = false
            }, 1200);
          },
          tip: () => {
            setTimeout(() => {
              this.btn_loadinga = false
            }, 1200);
          },
        })
      },
      importProductavailability() {
        this.loadinga = true
        let data = new FormData();
        data.append("file", this.form.file);
        data.append("api_id", this.$store.state.user.api_id);
        data.append("token", getToken());
        this.$post({
          url: "/app/assembly_orders/importProductavailability",
          upload: true,
          data,
          success: (res) => {
            if (!res.data.length) {
              this.importOrders()
            } else {
              this.list_show = true
              this.loadinglist = true
              res.data.forEach(item => {
                item.is_UnitPrice = true
                if (item.UnitPrice == 0 && item.OnHand == 0) {
                  item.is_UnitPrice = false
                }
              });
              this.list = res.data
              setTimeout(() => {
                this.loadinga = false
                this.loadinglist = false
              }, 500);
            }
          },
          tip: () => {
            setTimeout(() => {
              this.loadinga = false
            }, 1500);
          },
        })
      },
      importOrders() {
        this.loadinga = true
        let data = new FormData();
        data.append("file", this.form.file);
        data.append("api_id", this.$store.state.user.api_id);
        data.append("token", getToken());
        this.$post({
          url: "/app/assembly_orders/importAllocate",
          upload: true,
          data,
          success: (res) => {
            this.form = {
              file: ''
            }
            this.fileList = []
            this.$message({
              message: 'Success',
              type: 'success'
            });
            setTimeout(() => {
              this.loadinga = false
              this.show = false
              this.$router.go(-1)
            }, 1500);
          },
          tip: () => {
            setTimeout(() => {
              this.loadinga = false
            }, 1500);
          },
        })
      },
      opendata(data) {
        this.$alert(data.error, {
          showClose: false
        });
      },
      SelectionChangelist(data) {
        this.listidsarr = data
      },
      // 调整库存
      stockadjustment() {
        let data = []
        this.list.map((item) => {
          data.push({
            SKU: item.ProductCode,
            Quantity: item.Shortage,
            UnitCost: item.UnitPrice,
            BatchSN: item.BatchNubmer,
            require: item.require_qty,
            ProductID: item.ProductID,
          })
        })
        this.loadinglist = true
        this.btn_loadingd = true
        this.$post({
          url: "/app/assembly_orders/stockadjustment",
          data: {
            api_id: this.$store.state.user.api_id,
            data: JSON.stringify(data)
          },
          success: (res) => {
            this.list_show = false
            this.importOrders()
            setTimeout(() => {
              this.loadinglist = false
              this.btn_loadingd = false
            }, 1300);
          },
          tip: () => {
            setTimeout(() => {
              this.loadinglist = false
              this.btn_loadingd = false
            }, 1300);
          },
        })
      },
      // shortageDetail
      shortageDetail(row) {
        this.detail_list = []
        this.detail_show = true
        let ids = []
        this.tableData.map((item) => {
          ids.push(item.AssemblyNumber)
        })
        this.detail_loading = true
        this.$post({
          url: "/app/assembly_orders/shortageDetail",
          data: {
            api_id: this.$store.state.user.api_id,
            ids: ids.join(),
            ProductID: row.ProductID
          },
          success: (res) => {
            this.detail_show = true
            this.detail_list = res.data
            if (this.detail_list.length >= 0) {
              this.detail_loading = false
            }
          },
          tip: () => {
            this.list_show = false
            this.detail_loading = false
          },
        })
      },
    },
  }
</script>
<style lang='scss' scoped>
  .indexbox {
    width: 100%;
    height: 100%;
    .searchbox {
      width: 100%;
      padding: 20px 30px;
      background: #fff;
      border-bottom: 1px solid #babdcd;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tablebox {
      width: 100%;
      padding: 20px;
      .bodybox {
        width: 100%;
        padding: 20px;
        background: #fff;
        border-bottom: 1px solid #dbdbdb;
        .topbox {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          font-size: 14px;
          p:nth-child(1) {
            color: #333333;
            font-weight: 550;
          }
          p:nth-child(2) {
            color: #666666;
            line-height: 2;
          }
        }
        /deep/.el-button--default {
          color: #19cbbf;
          background-color: #fff;
          border-color: #19cbbf;
          border-radius: 4px;
        }
        .listbox {
          width: 100%;
          margin-top: 15px;
          padding: 10px 15px;
          background: #f1f6fb;
          border-radius: 10px;
          .titbox {
            width: 100%;
            color: #999999;
            font-size: 14px;
            p:nth-child(1) {
              color: #333333;
              font-weight: 550;
            }
            p:nth-child(2) {
              padding: 10px 0 20px;
            }
          }
          .tabbox {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #333333;
            font-size: 14px;
            font-weight: 550;
            padding: 8px 0;
            border-bottom: 1px solid #dbdbdb;
            p:nth-child(1) {
              width: 20%;
            }
          }
          .tablist {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-weight: 550;
            padding: 8px 0;
            line-height: 1.6;
            p:nth-child(1) {
              width: 20%;
              color: #333333;
              font-size: 14px;
            }
            p:nth-child(2) {
              width: 80%;
              color: #666666;
              font-size: 12px;
            }
          }
          /deep/.el-collapse {
            border: none;
            .el-collapse-item__header {
              background: none;
              color: #333333;
              font-size: 14px;
              font-weight: 550;
            }
            .el-collapse-item__content {
              padding-bottom: 0;
            }
            .el-collapse-item__wrap {
              border-bottom: none;
              background: none;
              .tabtit {
                margin-bottom: 20px;
                color: #999999;
                font-size: 14px;
              }
            }
          }
        }
      }
      .bodybox:nth-child(1) {
        border-radius: 10px 10px 0 0;
      }
      .bodybox:last-child {
        border-bottom: none;
        border-radius: 0 0 10px 10px;
        .upfile {
          padding: 10px 0 0;
        }
        /deep/.el-upload-dragger {
          width: 100%;
        }
      }
      .listsbox {
        width: 100%;
        padding: 20px;
        background: #fff;
      }
    }
    .formbox {
      /deep/.el-form-item {
        margin-bottom: 30px !important;
        .el-form-item__content {
          width: 100%;
          .el-button {
            width: 100%;
            height: 48px;
            background: $color;
            border-radius: 5px;
            border-color: $color;
          }
        }
      }
    }
    /deep/.el-dialog {
      min-width: 400px !important;
      .topbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          font-weight: 550;
        }
      }
      .el-dialog__body {
        padding-top: 10px;
      }
    }
  }
</style>
